import { Component, ElementRef } from '@angular/core';
import { HTTPService } from '../../../http.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AppSettings } from '../../../config/config.module';
import { Lib } from '../../../libraries/lib.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
    // moduleId: module.id,
    selector: 'content-component-actionsads',
    templateUrl: 'actionsads.component.html?v=${new Date().getTime()}',
    styleUrls: ['actionsads.component.css?v=${new Date().getTime()}'],
    providers: [HTTPService]
})

export class ActionsAdsComponent {
    id: any;
    storage_url: string;
    private sub: any;

    public PositionsData: any = [];
    public ItemData: any = {
        title: null,
        iframe: null,
        bck_image: '',
        max_view_count: '',
    };

    constructor(private _httpService: HTTPService, private http:HttpClient,
        private elRef: ElementRef, private route: ActivatedRoute, private router:Router) {
        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];
        });


        this.storage_url = AppSettings.STORAGE_URL;
        if (this.id != 'add') {
            this.id = parseInt(this.id);
            this.getItem();
        }else{
            this.getPositions();
        }
    }

    ngOnInit() {

    }

    saveItem(e) {
        e.preventDefault();
        let data: any = Lib.getFormData(e);

        let id:any = '';

        if(this.id != 'add'){
            id = '/'+this.id;
            this.editAds(id, data);
            return
        }

        this._httpService.postData('promotions', data).subscribe(
            data => this.ItemData = data,
            error => alert(error),
            () => this.checkAction()

        );
        // this. generateItem()

    }

    editAds(id, data){
        var x_key = this._httpService.getCookie('x_key');

        let headers = new HttpHeaders({
            'Authorization': 'Bearer ' + x_key,
            'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre- check=0',
            'Pragma': 'no-cache',
            'Expires': '0'
        });
    
        let options = {
            headers: headers,
        };

        this.http.patch(`${this._httpService.apiurl}promotions` + id, data, options).subscribe(console.log)
    }

    checkAction(){
        if (this.id == 'add') {
            var itm = JSON.parse(this.ItemData._body);
            this.router.navigate(['ads/'+ itm.id]);
        }else{
            this.generateItem()
        }
    }

    getItem() {
        this._httpService.getData('promotions/' + this.id).subscribe(
            data => this.ItemData = data,
            error => alert(error),
            () => this.generateItem()
        );

    }

    generateItem() {
        var itm = JSON.parse(this.ItemData._body);
        this.ItemData = itm.data;
        console.log(this.ItemData)
        this.ItemData['bck_image'] = this.storage_url + itm.image;
        this.getPositions();
    }

    getPositions() {
        this._httpService.getData('promotion-positions').subscribe(
            data => this.PositionsData = data,
            error => alert(error),
            () => this.generatePositions()
        );

    }

    generatePositions() {

        var items = JSON.parse(this.PositionsData._body);
        this.PositionsData = items.data;

    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

}
