import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { map, tap } from 'rxjs/operators'
import { NewActionService } from "./service/new-action.service";
import pasteAsPlainText from 'paste-as-plain-text';


@Component({
    selector: 'app-new-action',
    templateUrl: './new-action.component.html',
    styleUrls: ['./new-action.component.css'],
})

export class NewActionComponent implements OnInit {

    index:number;
    id:string;


    edit = false;
    content:any = '';
    title:string;
    content2 = '';
    mainImgUrlForSendRequest:FormData = new  FormData();
    showLoader = false;

    textBoldStyle = false;
    textItalicStyle = false;
    showDescription= false;
    imgShow:any;
    url:any;
    editUrl:any;
    sucsess = false;
    error = false;
    categoriArry:any;

    quizQuestionDetailsImg:any;

    quizQuestionArrayItems = [];
    quizQuestionDetails:any;
    changeImg = false;

    condition = 'first';
    surveyForm:FormGroup;
    saveSurveyForm:any;
    quizForm:FormGroup;
    quizQuestionGroup:FormGroup;
    quizAnswerDetailsForm:FormGroup;

    user = {
        category: [],
        team: [],
        league: [],
        footbolPlayer: [],
        match: null,
        // survey: null,
        news: [],
        status: null,
        date: '',
        isPined: null,
        challenge: null,
        randomQuestions:null,
        randomAnswers: null,
        playAgainBtn: null,
        main_video: '',
        meta_title: '',
        meta_description: '',
        meta_keywords: '',
        slug: ''
    };
    active = false;
    constructor(private fb:FormBuilder, private httpReq:NewActionService, private activatedRoute:ActivatedRoute){ }

    ngOnInit(): void {
        this.getLink();
        this.buildSurveyForm();
        this.quizFormBuilder();
        this.event();
        this.getCategoriList();
    }

    test(){
        let newIdArry = this.user.news.map(o => o.id.toString());
        let div = document.querySelectorAll('.linked-news');
        for(let i = 0; i < div.length; i++){
            if(!newIdArry.includes(div.item(i).id)){
                div.item(i).remove();
            }
        }
    }

    getLink(){
        this.activatedRoute.params.subscribe((param) => {
            this.id = param['id'];
            if(+this.id >= 0){
                this.httpReq.getArticle(this.id).subscribe((o:any) => {
                    this.createEditFild(o);
                    this.edit = true;
                })
            }
            return
        })
    }

    createHtml(item){
        let picTag = '';
        if(item.main_gallery_item && item.main_gallery_item.filename_preview) {
          picTag = '<img style="vertical-align:middle; margin-right: 17px;width: 70px;height: 70px;object-fit: cover;border-radius: 2px;" src="https://storage.lagazzetta.ge/uploads/posts/'+ item.main_gallery_item.filename_preview +'" />';
        }

        const html = '<div id="'+item.id+'" class="linked-news" style="margin-top: 20px; margin-bottom: 10px">' +
          '<a style="color: #000000; text-decoration: none;line-height: 1rem;display: flex;background: #F4F4F4;border-radius: 5px;width: 100%;height: 85px;align-items: center;padding: 8px;" href="/news/'+item.slug+'-'+item.id+'">' +
          picTag +
          '<strong><span style="font-size: 1rem">'+item.title+'</span></strong>' +
          '</a>' +
          '</div>';

        return html
    }

    addHtml(){
        let htmlArry = ''
        let div = document.querySelectorAll('.linked-news');
        let idArry = [];
        for(let i = 0; i < div.length; i++){
            idArry.push(+div.item(i).id)
        }

        this.user.news.forEach(o => {
            if(!idArry.includes(o.id) ){
                htmlArry += this.createHtml(o)
            }
        })
        return htmlArry
    }

    createEditFild(o:any){
        this.content = o.content;
        this.content2 = o.content
        this.title = o.title
        this.url = o.main_gallery_item ? `//storage.lagazzetta.ge/uploads/posts/${o.main_gallery_item.filename}` : '';
        this.editUrl = o.main_gallery_item ? o.main_gallery_item.id : null;
        this.user.category = o.categories;
        this.user.team = o.teams;
        this.user.league = o.leagues;
        this.user.footbolPlayer = o.players;
        this.user.match = o.match_id;
        this.user.news = this.returnHeshtegTitle(o.hashtags);
        this.user.status = o.is_draft;
        this.user.date = o.publish_date;
        this.user.isPined = o.is_pined;
        this.user.main_video = o.main_video ? `[type="video" id="${o.main_video_url.split('/').pop()}"]` : '';
        this.user.meta_title = o.meta_title;
        this.user.meta_description = o.meta_description;
        this.user.meta_keywords = o.meta_keywords;
        this.user.slug = o.slug;
    }

    returnHeshtegTitle(arry){
        let hashtags = [];
        arry.forEach(o => this.httpReq.getArticle(o.hashtag).subscribe(o => hashtags.push(o)));

        return hashtags
    }

    event2(event){
        this.content2 = event
    }

    closed(){
        this.httpReq.class.emit(false)
    }

    event(){
        pasteAsPlainText(
            document.getElementById('text')
        );
    }

    uploadAction(pined = false){
        const item = {
            title: this.title,
            is_draft: this.user.status,
            is_pined: pined ? 1 : 0,
            content: this.content2,
            main_gallery_item_id: null,
            categories: this.user.category.map(o => o.id),
            leagues: this.user.league.map(o => o.id),
            teams: this.user.team.map(o => o.id),
            players: this.user.footbolPlayer.map(o => o.id),
            hashtags: this.user.news.map(o => o.id),
            match_id: this.user.match,
            publish_date: this.user.date,
            gallery_item_ids: [],
            main_video: this.user.main_video,
            meta_title: this.user.meta_title,
            meta_description: this.user.meta_description,
            meta_keywords: this.user.meta_keywords,
            slug: this.user.slug,
            quiz: {
                is_challenge: this.user.challenge,
                has_random_questions: this.user.randomQuestions,
                has_random_answers: this.user.randomAnswers,
                has_play_again_btn: this.user.playAgainBtn,
                questions:this.quizQuestionArrayItems
            },
            poll: this.saveSurveyForm
        }

        console.log(item)

        if(this.edit){
            if(!this.editUrl && this.url || this.url && this.changeImg){
                this.httpReq.uploadImg(this.mainImgUrlForSendRequest).subscribe(res => {

                item.main_gallery_item_id = res[0].id;
                this.httpReq.editArticle(this.id,item).subscribe(() => this.showSucsess(), err => {this.showError()});

                },
                err => { this.showError() }
            )
            }else{
                this.httpReq.editArticle(this.id, {...item, main_gallery_item_id:this.editUrl}).subscribe(() => this.showSucsess(), err => {this.showError()});
            }

        }else{
            if(this.url){
                this.httpReq.uploadImg(this.mainImgUrlForSendRequest).subscribe(res => {
                item.main_gallery_item_id = res[0].id;

                this.httpReq.send(item).subscribe(() => this.showSucsess(), err => {this.showError()});
                },
                err => {this.showError()}

            )}else{
                this.httpReq.send(item).subscribe(() => this.showSucsess(), err => {this.showError()});
            }
        }
    }

    draft(){
        this.user.status = 1;
    }

    showSucsess(){
        this.sucsess = true;
        setTimeout(() => {
            this.sucsess = false;
        }, 3000);
    }

    showError(){
        this.error = true;
        setTimeout(() => {
            this.error = false;
        }, 3000);
    }

    buildSurveyForm(){
        this.surveyForm = this.fb.group({
            question: new FormControl(''),
            answers: new FormArray([
                this.fb.group({
                    answer: new FormControl('')
                })
            ]),
        })
    }

    firstImg(event){
        var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);


        this.mainImgUrlForSendRequest.append('files[]', event.target.files[0], event.target.files[0].name)
		reader.onload = (_event) => {
			this.url = reader.result;
		}

        this.changeImg = true;
    }

    // text area button functions
    textBold(){
        this.textBoldStyle = !this.textBoldStyle;
    }

    textItalic(){
        this.textItalicStyle = !this.textItalicStyle;
    }

    addLink() {
        var linkURL = prompt('Enter a URL:', 'http://');
        var sText = document.getSelection();

        document.execCommand('insertHTML', false, '<a href="' + linkURL + '" target="_blank">' + sText + '</a>');
    }


    image(event){
        const contentImg = new FormData();
        contentImg.append('files[]', event.target.files[0], event.target.files[0].name);
        this.httpReq.uploadImg(contentImg).subscribe((o:any) => {
            let img = `</br> <img src="https://storage.lagazzetta.ge/uploads/posts/${o[0].filename}" alt="" style="margin: 0px auto; display:block; max-width:100%; max-height:500px" >`
            document.execCommand("insertHTML", false, img);
        });
    }

    uploadVideo(event){
        this.showLoader = true;
        const contentVideo = new FormData();
        contentVideo.append('video', event.target.files[0], event.target.files[0].name);
        this.httpReq.uploadVideo(contentVideo).subscribe((o:any) => {
            this.showLoader = false;
            let url = o.video_url
            let video = `</br> <video width="600" height="400" style="margin: 10px auto; display:block" controls><source src="https://storage.lagazzetta.ge${url}" type="video/ogg">Your browser does not support the video tag.</video> </br>`
            document.execCommand("insertHTML", false, video);
        })
    }

    // text area button functions

    // viqtorina
    routing(id){
        this.index = id;
        this.quizQuestionDetails = this.quizQuestionArrayItems[id];
        this.buildQuizAnswerDetailsForm();
        this.quizQuestionDetails.answers.forEach(o => {
            this.quizQuestionDetailsArry.push(this.fb.group(o))
        })
    }

    quizFormBuilder(){
        this.quizForm = this.fb.group({
            text: new FormControl(''),
            answers: new FormArray([
                new FormGroup({
                    text: new FormControl(''),
                    is_correct: new FormControl(false),
                    showDes: new FormControl(false),
                    explanation: new FormControl(null),
                })
            ]),
        })
    }

    addQuizAnswer(){
        const control = new FormGroup({
            text: new FormControl(''),
            is_correct: new FormControl(false),
            showDes: new FormControl(false),
            explanation: new FormControl(null),
        });
        (this.quizForm.get('answers') as FormArray).push(control);
    }

    deleteQuizAnswer(index){
        (this.quizForm.get('answers') as FormArray).removeAt(index)
    }

    get quizQuestionArry(){
        return this.quizForm.controls['answers'] as FormArray
    }

    saveQuizQuestion(){
        this.quizQuestionArrayItems.push({...this.quizForm.value, image:this.imgShow});
        this.quizFormBuilder();
        this.imgShow = '';
        this.quizForm.reset();
    }

    quizImg(event){
        var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);

		reader.onload = (_event) => {
			this.imgShow = reader.result;
		}
    }

    deleteSmallImg(){
        this.imgShow = null;
    }

    // viqtorina


    // quiz answer details
    buildQuizAnswerDetailsForm(){
        this.quizQuestionDetailsImg = this.quizQuestionDetails.image;
        this.quizAnswerDetailsForm = this.fb.group({
            text: new FormControl(`${this.quizQuestionDetails.text}`),
            answers: new FormArray([]),
        })
    }

    get quizQuestionDetailsArry(){
        return this.quizAnswerDetailsForm.controls['answers'] as FormArray
    }

    back(){
        this.quizQuestionArrayItems[this.index] = {...this.quizAnswerDetailsForm.value, image:this.quizQuestionDetailsImg}
        this.quizQuestionDetails = null;
    }

    addQuizDetailAnswer(){
        const control = new FormGroup({
            text: new FormControl(''),
            is_correct: new FormControl(false),
            showDes: new FormControl(false),
            explanation: new FormControl(null),
        });
        (this.quizAnswerDetailsForm.get('answers') as FormArray).push(control);
    }

    deleteQuizDetailAnswer(id){
        (this.quizAnswerDetailsForm.get('answers') as FormArray).removeAt(id)
    }

    detailQuizImg(event){
        var reader = new FileReader();
		reader.readAsDataURL(event.target.files[0]);

		reader.onload = (_event) => {
			this.quizQuestionDetailsImg = reader.result;
		}
    }

    deleteDetailQuizImg(){
        this.quizQuestionDetailsImg = null;
    }

    saveQuizQuestionInDetailPage(){
        this.quizQuestionArrayItems[this.index] = {...this.quizAnswerDetailsForm.value, image:this.quizQuestionDetailsImg}
        this.quizQuestionDetailsImg = null;
        this.quizQuestionDetails = null;
    }

    deleteQizQuestion(){
        this.quizQuestionArrayItems.splice(this.index,1);
        this.quizQuestionDetailsImg = null;
        this.quizQuestionDetails = null;
    }

    cancelledQuiz(){
        this.condition = 'first';
        this.quizForm.reset();
    }
    // quiz answer details


    addAnswer(){
        const control = this.fb.group({answer: new FormControl('')});
        (this.surveyForm.get('answers') as FormArray).push(control);
    }

    deleteAnswer(index){
        (this.surveyForm.get('answers') as FormArray).removeAt(index)
    }

    saveSurvey(){
        // this.httpReq.sendSurvey(this.surveyForm.value).subscribe(console.log)
        this.saveSurveyForm = this.surveyForm.value;
        this.surveyForm.reset();
    }

    formActive(){
        this.active = !this.active;
        if(this.active === false){
            this.content2 += this.addHtml()
            this.content = this.content2;
            setTimeout(() => {
                this.test()
            },200);
        }
    }

    cancelledSurvey(){
        this.condition = 'first';
        this.surveyForm.reset();
    }

    getCategoriList(){
        this.httpReq.getCategoriesWithoutTreeList().subscribe((o) => {
            this.categoriArry = o;
        })
    }

    requestAutocompleteNewsItems = (text: any): Observable<any> =>{
        return this.httpReq.searchNews(text).pipe(map((data: any) => {
            return data.data;
        }));
    }

    requestAutocompletePlayersItems = (text: any): Observable<any> => {
        return this.httpReq.searchPlayers(text).pipe(map((data: any) => {
            return data.data;
        }));
    }

    requestAutocompleteTeamsItems = (text: any): Observable<any> => {
        return this.httpReq.searchTeams(text).pipe(map((data: any) => {
            return data.data;
        }));
    }

    requestAutocompleteLeaguesItems = (text: any): Observable<any> => {
        return this.httpReq.searchLeagues(text).pipe(map((data: any) => {
            return data.data;
        }));
    }
}
